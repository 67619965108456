import React, { useEffect } from "react"
import rehypeReact from "rehype-react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/Seo/Seo"
import PageTransition from "../components/PageTransition"
import { useStateValue } from "../components/State"
import BlockYoutube from "../components/BlockYoutube"
import BlockText from "../components/BlockText"
import BlockImage from "../components/BlockImage"
import BlockQuote from "../components/BlockQuote"
import BlockIntroduction from "../components/BlockIntroduction"
import ArticleListingItem from "../components/ArticleListingItem"
import "./article.sass"
import clsx from "clsx"

const renderAst = new rehypeReact({
	createElement: React.createElement,
	components: {
		"block-introduction": BlockIntroduction,
		"block-youtube": BlockYoutube,
		"block-text": BlockText,
		"block-image": BlockImage,
		"block-quote": BlockQuote
	}
}).Compiler

const ArticleWrapper = (props) => {

	return <PageTransition {...props}> <Article {...props} /> </PageTransition>

}

const Article = (props) => {

	const { data } = props
	const { markdownRemark: post } = data
	const [state, dispatch] = useStateValue()

	const {
		frontmatter,
		timeToRead,
		parent,
		fields
	} = post

	const {
		category,
		collection,
		slug,
	} = fields

	const {
		title,
		shortTitle,
		keywords,
		contentWarning,
		contentNote,
		articleAuthor,
		videoCredit,
		introduction,
		featuredImageCredit,
		featuredImage,
		articlePublicationDate,
		abstract,
		draft,
	} = frontmatter

	const { previousPost, nextPost } = props.pageContext
	const seoTitle = shortTitle ? shortTitle : title
	const description = abstract ? abstract : introduction

	useEffect(() => {

		if (state.page.title !== title) {

			dispatch({ type: "SET_PAGE_TITLE", title })

		}

		return () => {

			if (state.page.title) {

				dispatch({ type: "RESET_PAGE_TITLE" })

			}

		}

	}, [dispatch, title, state])

	const articleClassName = clsx({
		"hasFeaturedIamge": featuredImage,
		"isDraft": draft
	})

	const image = getImage(featuredImage)
	const avatarImage = getImage(articleAuthor.avatar)
	const seoImage = image?.images?.fallback?.src ?? null

	return (

		<div className="articlePage">

			<Seo title={seoTitle} keywords={keywords} description={description} image={seoImage} pathname={slug} />

			{image && <GatsbyImage loading="eager" alt={title} title={title} className="featured-image" image={image} />}

			<article className={articleClassName}>

				<header className="page">

					{draft && <div className="draft-warning">

						<h3>Draft Warning:</h3>
						This content is a draft and not intended for public view. If you have been sent this draft please do not forward or post it to social media.

					</div>}

					{contentWarning && <div className="content-warning">

						<h3>content Warning:</h3>
						{contentWarning}

					</div>}

					<div className="category-collection">

						<div className="category">{category}</div>

						{ (collection && (collection !== category)) &&
							<div className="collection">&nbsp;{collection}</div>
						}

					</div>

					<h1>{title}</h1>

					<div className="article-meta">

						<div className="articleAuthor">Written by:&nbsp;

							<strong>{articleAuthor.name}&nbsp;</strong>

						</div>

						<div className="time-to-read">Reading time:&nbsp;

							<strong>{timeToRead} min&nbsp;</strong>

						</div>

					</div>

				</header>

				<div className="page">{renderAst(post.htmlAst)}</div>

				<footer>

					<div className="article-meta">

						{contentNote && <div className="content-note">{contentNote}</div>}

						<div className={(articleAuthor && articleAuthor.avatar) ? "articleAuthor-bio" : "articleAuthor-bio no-avatar"} >

							{articleAuthor && avatarImage && <GatsbyImage alt={articleAuthor.name} className="articleAuthor-avatar" image={avatarImage} />}

							<div className="articleAuthor-meta">

								<h2>Written by {articleAuthor.name}</h2>

								<p className="bio">{articleAuthor.bio}</p>

							</div>

						</div>

						<div className="publication-date">Published:&nbsp;

							<strong>{articlePublicationDate}&nbsp;</strong>

						</div>

						<div className="last-modified">Last modified:&nbsp;

							<strong>{parent.mtime}&nbsp;</strong>

						</div>

						{videoCredit &&

							<div className="image-credit">Video by:&nbsp;

								<strong>{videoCredit}&nbsp;</strong>

							</div>

						}

						{featuredImageCredit

							&& (

								<div className="image-credit">Lead image by:&nbsp;

									<strong>{featuredImageCredit}&nbsp;</strong>

								</div>

							)

						}

					</div>

					<div className="next-and-previous">

						{previousPost && (

							<div className="previousNext">

								<h2>Previous article</h2>
								<ArticleListingItem {...previousPost.fields} {...previousPost.frontmatter} timeToRead={previousPost.timeToRead} />

							</div>

						)}

						{nextPost && (

							<div className="previousNext">

								<h2>Next article</h2>
								<ArticleListingItem {...nextPost.fields} {...nextPost.frontmatter} timeToRead={nextPost.timeToRead} />

							</div>

						)}

					</div>

				</footer>

			</article >

		</div>

	)

}

Article.propTypes = {

	data: PropTypes.object.isRequired,

}

export default ArticleWrapper

export const pageQuery = graphql`

	query ArticleBySlug($slug: String!) {

		markdownRemark(

			fields: {

				slug: {
					eq: $slug
				},

			}

		) {
			id
			htmlAst
			wordCount {
				words
			}
			timeToRead
			fields {
				slug
				category
				collection
			}
			parent {
				...on File {
					mtime(formatString: "MMMM Do YYYY")
				}
			}
			frontmatter {
				title
				articlePublicationDate(formatString: "MMMM Do YYYY")
				shortTitle
				abstract
				contentWarning
				contentNote
				keywords
				draft
				articleAuthor {
				...articleAuthorQuery
				}
				videoCredit
				introduction
				featuredImageCredit
				featuredImage {
					childImageSharp {
						gatsbyImageData(
							width: 2500,
							placeholder: BLURRED,
							layout: CONSTRAINED,
							formats: [AUTO, WEBP, AVIF]
						)
					}
				}

			}

		}

	}

`