import React from "react"
import PropTypes from "prop-types"
import "./BlockText.sass"

const BlockText = (props) => {

	const { children } = props

	return (

		<div className="block-text">

			{children}

		</div>
	)

}

BlockText.propTypes = {

	children: PropTypes.node,

}

export default BlockText