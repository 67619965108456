import React from "react"
import PropTypes from "prop-types"
import "./BlockYoutube.sass"

const BlockYoutube = (props) => {

	const { video, children, title } = props
	const videoIdRegularExpression = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i
	const videoID = video.match(videoIdRegularExpression)[1]
	
	return (

		<div className="block-youtube">

			<div className='embed-container'>
				<iframe 
					title="YouTube video"
					src={`https://www.youtube-nocookie.com/embed/${videoID}`}
					allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen/>
			</div>

			{children &&
				<div className="description">
					{title && <h3>{title}</h3>}
					{children}
				</div>
			}

		</div>
	)

}

BlockYoutube.propTypes = {

	video: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),

}

export default BlockYoutube