import React from "react"
import PropTypes from "prop-types"
import "./BlockImage.sass"

const BlockImage = (props) => {

	const { children, copyright, caption } = props

	return (

		<div className="block-image">

			{children}

			{caption && <div className="image-caption">{caption}</div>}
			{copyright && <div className="image-copyright">© {copyright}</div>}

		</div>
	)

}

BlockImage.propTypes = {

	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
	copyright: PropTypes.string,
	caption: PropTypes.string,

}

export default BlockImage