import React from "react"
import PropTypes from "prop-types"
import "./BlockIntroduction.sass"

const BlockIntroduction = (props) => {

	const { children } = props

	return (

		<div className="block-Introduction">

			{children}

		</div>

	)

}

BlockIntroduction.propTypes = {

	children: PropTypes.node,

}

export default BlockIntroduction